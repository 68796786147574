.posts{
  margin-top: $navigation-height-xs;
  padding: 50px 20px;
  a:hover{
    color: darken($brand-primary, 15%);
  }
  h1{
    font-size: 44px;
    line-height: 50px;
    margin-top: 0;
  }
  h2{
    @extend h3;
  }
  img{
    width: 100%;
    height: auto;
  }
  article{
    border-bottom: 1px solid $brand-gray;
    padding: 50px 0;
    header{
      margin-bottom: 20px;
      .post-thumbnail{
        margin-bottom: 20px;
      }
    }
    .wp-video{
      max-width: 100%;
      video{
        width: 100%;
      }
    }
  }
  .posts--content > article{
    &:first-child{
      padding-top: 0;
    }
  }
  .author{
    .author--avatar,
    .author--name{
      line-height: 50px;
      display: inline-block;
    }
    .author--avatar{
      img{
        width: auto;
        height: 100px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
    }
    .author--name{
      margin-left: 5px;
      .autor--name--text{
        font-weight: 500;
      }
    }
  }
  .post-meta{
    margin-top: 10px;
    h4{
      font-weight: 500;
    }
  }
  .posts--sidebar{
    > div{
      margin-bottom: 50px;
    }
  }
  .post-social-share{
    h4{
      margin-top: 10px;
      margin-bottom: 0;
      text-align: center;
    }
    ul{
      text-align: center;
      li{
        display: inline-block;
        padding: 15px;
        a{
          color: $text-color;
          -webkit-transition: color .2s;
          -moz-transition: color .2s;
          -ms-transition: color .2s;
          -o-transition: color .2s;
          transition: color .2s;
          &:hover{
            color: $brand-gray;
          }
        }
        .svg-inline--fa{
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  .comments-area{
    label{
      display: block;
      font-weight: 500;
    }
    a{
      font-size: 16px;
      color: $brand-primary;
      &:hover{
        color: $brand-primary;
      }
    }
    input[type="submit"]{
      @extend .btn, .btn-primary;
    }
    .comment-author{
      img{
        display: none;
      }
    }
    .comment-meta{
      padding: 0;
      background: transparent;
      padding: 15px 0;
      a{
        color: $text-color;
        &:hover{
          color: $text-color;
        }
      }
    }
    .comment-list{
      li{
        margin-bottom: 20px;
        padding: 20px;
        background: #fff;
      }
    }
    .comment-content{
      padding-top: 20px;
    }
    .children{
      border-top: 1px solid $text-color;
      padding-left: 40px;
      .comment-body{
        border-bottom: 0;
      }
    }
    .comment-respond{
      padding-top: 50px;
      input[type="text"],
      input[type="email"],
      input[type="url"],
      textarea{
        width: 100%;
        @media (min-width: $screen-size-sm){
          width: auto;
        }
      }
    }
    article{
      padding: 10px 0;
    }
  }
  .cta-row{
    text-align: center;
    margin-top: 50px;
  }
  /* Search */
  .search-form {
    position: relative;
    input{
      color: #666;
      background: #fff;
      background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
      border: 1px solid #bbb;
      -webkit-border-radius: 0;
      border-radius: 0;
      display: block;
      padding: 0.7em;
      width: 100%;
    }
    .search-submit {
      padding: 0 20px;
      bottom: 3px;
      position: absolute;
      right: 3px;
      top: 3px;
      -webkit-border-radius: 0px;
      border-radius: 0px;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $text-color;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      text-shadow: none;
      -webkit-transition: background-color .2s, color .2s;
      -moz-transition: background-color .2s, color .2s;
      -ms-transition: background-color .2s, color .2s;
      -o-transition: background-color .2s, color .2s;
      transition: background-color .2s, color .2s;
      &:hover{
        background-color: #fff;
        color: $brand-primary;
      }
      .icon {
        height: 24px;
        top: -2px;
        width: 24px;
      }
    }
  }
  @media (min-width: $screen-size-sm) {
    padding: 50px 0;
    margin-top: $navigation-height;
    .pure-g{
      margin-left: -15px;
      margin-right: -15px;
    }
    .posts--content,
    .posts--sidebar{
      padding: 15px;
    }
  }
  &.single{
    article{
      border: 0;
      padding-bottom: 0;
    }
  }
  .post-further{
    margin: 50px 0;
  }
}
.page-header{
  margin-top: $navigation-height-xs;
  + .posts{
    margin-top: 0;
  }
  @media (min-width: $screen-size-sm) {
    margin-top: 0;
  }
}