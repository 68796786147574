/* Browser reset */
input,
input:hover,
input:active,
input:focus,
textarea,
textarea:hover,
textarea:active,
textarea:focus,
.input-text-style{
  outline: 0;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid $brand-gray;
  color: $text-color;
  border: 1px solid $brand-gray;
  padding: 10px;
}
.nf-table-display{
  width: 100%;
}
.nf-form-errors,
.nf-form-fields-required,
.nf-after-field{
  display: none;
}
.nf-field-element{
  input,
  textarea{
    @extend .input-text-style;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"]{
    height: 50px;
    &.input-block{
      display: block;
    }
  }
}
.checkbox-wrap .nf-field-element label:before, .checkbox-wrap .nf-field-label label:before, .listcheckbox-wrap .nf-field-element label:before, .listcheckbox-wrap .nf-field-label label:before{
  font-size: 17px !important;
  left: -27px !important;
}
.checkbox-wrap.nf-error .nf-field-label label:after{
  border-color: #e80000;
}
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color:    $brand-medium-gray;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:    $brand-medium-gray;
  opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color:    $brand-medium-gray;
  opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:    $brand-medium-gray;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color:    $brand-medium-gray;
}

::placeholder { /* Most modern browsers support this now. */
  color:    $brand-medium-gray;
}