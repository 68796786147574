@media (min-width: $screen-size-sm){
  .content,
  .content-small{
    margin: auto;
  }
  .content{
    max-width: $content-width;
  }
  .top-navigation .content{
    max-width: $content-width-navigation;
  }
  .content-small{
    max-width: $content-small-width;
  }
}