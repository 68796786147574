.svg-icon-line{
  display: inline;
  position: relative;
  .svg-icon{
    position: absolute;
    top: 50%;
    left: 5px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    svg{
      width: 25px;
      vertical-align: middle;
    }
    &.black{
      color: $text-color;
    }
  }
}
.clearfix{
  clear: both;
}
.aligncenter{
  display: block;
  margin: auto;
}
.google-maps-consent{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  border: 1px solid $brand-dark;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  &:hover{
    background: $brand-gray;
  }
}