*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background-color: $body-bg;
}
a{
  color: $link-color;
  text-decoration: none;
  -webkit-transition: color .2s;
  -moz-transition: color .2s;
  -ms-transition: color .2s;
  -o-transition: color .2s;
  transition: color .2s;
  &.inline-link{
    position: relative;
    &:after{
      content: ">";
      position: absolute;
      right: -15px;
      -webkit-transition: right .2s ease-in;
      -moz-transition: right .2s ease-in;
      -ms-transition: right .2s ease-in;
      -o-transition: right .2s ease-in;
      transition: right .2s ease-in;
    }
    &:hover{
      &:after{
        right: -18px;
      }
    }
  }
}
.btn,
.nf-form-content button,
.nf-form-content input[type=button],
.nf-form-content input[type=submit],
.nf-form-content button,
.nf-form-content input[type=button],
.nf-form-content input[type=submit]{
  padding: 5px;
  border: 1px solid $text-color;
  -webkit-transition: background-color .4s, color .4s, border-color .4s;
  -moz-transition: background-color .4s, color .4s, border-color .4s;
  -ms-transition: background-color .4s, color .4s, border-color .4s;
  -o-transition: background-color .4s, color .4s, border-color .4s;
  transition: background-color .4s, color .4s, border-color .4s;
}
.btn.btn-primary,
.nf-form-content button.ninja-forms-field,
.nf-form-content input[type=button].ninja-forms-field,
.nf-form-content input[type=submit].ninja-forms-field,
.nf-form-content button.ninja-forms-field,
.nf-form-content input[type=button].ninja-forms-field,
.nf-form-content input[type=submit].ninja-forms-field{
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
  color: #fff;
  &:hover{
    background-color: transparent;
    color: $brand-primary;
  }
}
.btn.btn-white{
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}

.btn.btn-arrow,
.nf-form-content button.ninja-forms-field,
.nf-form-content input[type=button].ninja-forms-field,
.nf-form-content input[type=submit].ninja-forms-field,
.posts .comments-area input[type=submit]{
  position: relative;
  font-size: 16px;
  line-height: 18px;
  padding: 5px 51px 5px 25px;
  border-radius: 25px;
  height: auto;
  svg{
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: color .4s;
    -moz-transition: color .4s;
    -ms-transition: color .4s;
    -o-transition: color .4s;
    transition: color .4s;
    display: inline-block;
    vertical-align: middle;
  }
  &:hover{
    svg{
      color: $brand-primary;
    }
  }
}
.nf-form-content button.ninja-forms-field,
.nf-form-content input[type=button].ninja-forms-field,
.nf-form-content input[type=submit].ninja-forms-field,
.posts .comments-area input[type=submit]{
  padding: 5px 25px;
}
.back-link{
  position:relative;
  padding-left: 20px;
  &:before{
    content: "<";
    position: absolute;
    left: 5px;
    -webkit-transition: left .2s ease-in;
    -moz-transition: left .2s ease-in;
    -ms-transition: left .2s ease-in;
    -o-transition: left .2s ease-in;
    transition: left .2s ease-in;
  }
  &:hover{
    &:before{
      left: 2px;
    }
  }
}
p{
  margin-bottom: 20px;
}
img{
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
ul{
  list-style: none;
}
input[type=search] {
  box-sizing: border-box;
}
@media (max-width: $screen-size-xs) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: $screen-size-sm){
  .hidden-md{
    display: none !important;
  }
}
@media (min-width: $screen-size-md){
  .hidden-lg{
    display: none !important;
  }
}
.text-left{
  text-align: left;
  color: #efefef;
}
.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.text-color-white{
  color: #fff !important;
  h1,h2,h3,h4,h5{
    color: #fff !important;
  }
}
.text-color-black{
  color: #000 !important;
  h1,h2,h3,h4,h5{
    color: #000 !important;
  }
}
.invisible{
  opacity: 0 !important;
}