/* colors */
$brand-primary: #c4362c;
$brand-dark-blue: #284395;
$brand-blue: #3681bb;
$brand-light-blue: #4aa9de;
$brand-gray: #f4f5f7;
$brand-medium-gray: #939393;
$brand-dark: #353535;

$body-bg: $brand-gray;

$text-color: #282828;
$link-color: $brand-primary;
$footer-color: #939394;
$footer-background-color: #353535;

$navigation-background: $brand-dark-blue;
$navigation-seperator-color: rgba(255,255,255,0.3);

$overlay-background-color: rgba(0,0,0,.3);

/* Sizes */
$navigation-height: 70px;
$navigation-height-xs: 50px;

$content-width: 1140px;
$content-small-width: 900px;
$content-width-navigation: $content-width;

/* Screen Sizes */
$screen-size-xs-portrait: 448px;
$screen-size-xs: 768px;
$screen-size-sm: 992px;
$screen-size-md: 1024px;
$screen-size-lg: 1280px;
$screen-size-xl: 1440px;